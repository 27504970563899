<template >
  <div style="width: 100%">
    <div style="width: 100%; height: 100vh; overflow: hidden" class="bgImg">'


      <div style="width: 28%; background: #FFFFFF;margin: 13% 60% 50px;">

        <div style="width: 80%; margin: 0 auto; padding-top: 30px">

          <div class="item" style="font-size: 22px; color: #0080FF">账号登录</div>

          <el-form :model="user" status-icon :rules="rules" ref="user" label-width="10px"
                   class="demo-ruleForm" style="margin-top: 30px" @submit.native.prevent>

            <el-form-item prop="username">
              <el-input
                  placeholder="请输入手机号"
                  style="float: left;"
                  v-model="user.username"
                  prefix-icon="el-icon-user-solid"
              />
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                  placeholder="请输入密码"
                  style="float: left;margin-top: 12px;"
                  type="password"
                  v-model="user.password"
                  prefix-icon="el-icon-lock"
              />
            </el-form-item>

            <el-form-item>
              <span style="cursor: pointer;float: right;color:#959595;"
                    @click="dialogFormVisible = true">忘记密码？</span>
              <div class="item">
                <button
                    style="background: dodgerblue; border-radius: 5px; color: white; width: 100%; padding: 10px; cursor: pointer"
                    @click="login('user')">登录
                </button>
              </div>
              <div class="item" style="padding: 10px 0;">
                <span style="cursor: pointer;">没有账号,</span>
                <span style="cursor: pointer; color: #409EFF" @click="goRegister">去注册</span>
              </div>

            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>
    <Bottom/>

    <!-- 弹窗   -->
    <el-dialog title="留言" center :visible.sync="dialogFormVisible" width="50%"
               :close-on-click-modal="false">
      <el-form :model="message" :rules="messageRules" ref="message">
        <el-form-item label="留言主题" prop="theme" label-width="150px">
          <el-input v-model="message.theme" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="留言内容" prop="content" label-width="150px">
          <el-input v-model="message.content" type="textarea" autocomplete="off" style="width: 80%"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save('message')">提 交</el-button>
      </div>
    </el-dialog>
  </div>


</template>

<script>

import Bottom from "../home/bottom";
import {subMessage, updateLoginCount} from "../api/user";

export default {
  components: {Bottom},
  name: "Login",
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      dialogFormVisible: false,
      message: {
        theme: '',
        content: ''
      },
      messageRules: {
        theme: [
          {required: true, message: '请输入留言主题', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入留言内容', trigger: 'blur'},
        ],
      }
    }
  },

  methods: {
    save(message) {
      this.$refs[message].validate((valid) => {
        if (valid) {
          subMessage(this.message).then(res => {
            this.$message({
              type: "success",
              message: res.data.msg
            })
            this.$refs.message.resetFields();
            this.dialogFormVisible = false;
          })
        } else {
          return false;
        }
      });
    },
    goRegister() {
      this.$router.replace("/register")
    },
    login(user) {
      this.$refs[user].validate((valid) => {
        if (valid) {
          this.$store.dispatch("LoginByUsername", Object.assign({}, this.user)).then(() => {
            updateLoginCount();
            this.$router.push({path: "/front/home"});
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.bgImg {
  background: url("../assets/login_background.png") no-repeat;
  background-size: 100% 100vh;
}

.item {
  text-align: center;
  padding: 10px 0
}

</style>
